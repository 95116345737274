import React, { useEffect, useState } from 'react'
import { setOrders as setOrdersAction } from '../../../domain/settings/slice'
import { RootState } from '../../../domain/rootReducer'
import { connect } from 'react-redux'
import { Button, Card, Tabs } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { EditableOrdersTable } from '../EditableTable/EditableOrdersTable'
import { Link } from 'react-router-dom'
import { ordersRootURL } from '../../Common/constants'
import { getWorkflowFromURL } from '../utils'

const mapDispatch = {
  setOrders: setOrdersAction,
} as const
const mapState = ({ settings }: RootState) => ({ settings } as const)

type Props = ReturnType<typeof mapState> & typeof mapDispatch

export const OrdersComponent = ({ settings }: Props) => {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [workflow, setWorkflow] = useState<string>(getWorkflowFromURL(location.pathname))

  useEffect(() => {
    const handleBackButton = () => {
      setWorkflow(getWorkflowFromURL(location.pathname))
    }
    window.addEventListener('popstate', handleBackButton)
    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [])

  const refreshOrders = () => {
    setRefresh((prev) => !prev)
  }

  const refreshOrdersButton = {
    right: (
      <Button onClick={refreshOrders} type="link" size="large" icon={<SyncOutlined />}>
        Refresh orders
      </Button>
    ),
  }

  const handleTabChange = (key: string) => {
    setWorkflow(key)
  }

  const tabItems = [
    {
      label: (
        <Link to={`${ordersRootURL}/all-orders`} style={{ color: 'inherit' }}>
          ALL
        </Link>
      ),
      key: '',
      children: <EditableOrdersTable workflow="" refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/balsamic`} style={{ color: 'inherit' }}>
          BALSAMIC
        </Link>
      ),
      key: 'balsamic',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/mip-dna`} style={{ color: 'inherit' }}>
          MIP DNA
        </Link>
      ),
      key: 'mip-dna',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/mip-rna`} style={{ color: 'inherit' }}>
          MIP RNA
        </Link>
      ),
      key: 'mip-rna',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/fluffy`} style={{ color: 'inherit' }}>
          FLUFFY
        </Link>
      ),
      key: 'fluffy',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/microsalt`} style={{ color: 'inherit' }}>
          MICROSALT
        </Link>
      ),
      key: 'microsalt',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/mutant`} style={{ color: 'inherit' }}>
          MUTANT
        </Link>
      ),
      key: 'mutant',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/rnafusion`} style={{ color: 'inherit' }}>
          RNAFUSION
        </Link>
      ),
      key: 'rnafusion',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/taxprofiler`} style={{ color: 'inherit' }}>
          TAXPROFILER
        </Link>
      ),
      key: 'taxprofiler',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/raw-data`} style={{ color: 'inherit' }}>
          RAW DATA
        </Link>
      ),
      key: 'raw-data',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/tomte`} style={{ color: 'inherit' }}>
          TOMTE
        </Link>
      ),
      key: 'tomte',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/raredisease`} style={{ color: 'inherit' }}>
          RAREDISEASE
        </Link>
      ),
      key: 'raredisease',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${ordersRootURL}/nallo`} style={{ color: 'inherit' }}>
          NALLO
        </Link>
      ),
      key: 'nallo',
      children: <EditableOrdersTable workflow={workflow} refresh={refresh} />,
    },
  ]

  const tabStyle = {
    backgroundColor: settings.themeMode ? '#141414' : 'white',
  }

  return (
    <>
      <Card>
        <Tabs
          type="card"
          items={tabItems}
          tabBarExtraContent={refreshOrdersButton}
          onTabClick={handleTabChange}
          activeKey={workflow}
          tabBarStyle={{ position: 'sticky', top: 64, zIndex: 10, ...tabStyle }}
        />
      </Card>
    </>
  )
}

export const OrdersPage = React.memo(connect(mapState, mapDispatch)(OrdersComponent))
