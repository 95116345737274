import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { HomePage } from './Home/HomePage'
import { UnauthorizedPage } from './Common/UnauthorizedPage'
import { GitHubStatsPage } from './GitHubStats/GitHubStatsPage/GitHubStatsPage'
import { AnalysesPage } from './Trailblazer/Analyses/AnalysesPage'
import { AnalysisPage } from './Trailblazer/Analysis/AnalysisPage'
import { AnalysesStatsPage } from './Trailblazer/AnalysesStats/AnalysesStatsPage'
import { GenotypeHomePage } from './Genotype/GenotypeHomePage'
import { PlateUpload } from './Genotype/PlateUpload/PlateUpload'
import { AtlasPage } from './Atlas/AtlasPage'
import { OwnerPage } from './Atlas/OwnerPage'
import { LabPrepsHomePage } from './LabPreps/LabPrepsHomePage'
import { PlatePage } from './Genotype/PlatePage/PlatePage'
import { PlateListPage } from './Genotype/PlateListPage/PlateListPage'
import { SamplePage } from './Genotype/SamplePage/SamplePage'
import { UserListPage } from './Genotype/UserListPage/UserListPage'
import { UserPage } from './Genotype/UserPage/UserPage'
import { StepsTable } from './LabPreps/StepsTable/StepsTable'
import { MatchPage } from './Genotype/MatchPage/MatchPage'
import { SampleTrendingPage } from './LabPreps/TrendingGraph/pages/SampleTrendingPage'
import { TurnaroundTimeTrendingPage } from './LabPreps/TrendingGraph/pages/TurnaroundTimeTrendingPage'
import { SamplesPage } from './Genotype/SamplesPage/SamplesPage'
import { Pyramid } from './GitHubStats/Pyramid/Pyramid'
import { IssueDrivers } from './GitHubStats/IssueDrivers/IssueDrivers'
import { TrailblazerHomePage } from './Trailblazer/TrailblazerHomePage'
import { analysesRootURL, ordersRootURL, projectPlanningRootURL } from './Common/constants'
import { Projects } from './GitHubStats/Projects/Projects'
import { Milestones } from './GitHubStats/Milestones/Milestones'
import { Areas } from './GitHubStats/Areas/Areas'
import { Loading } from './Common/Loading'
import { OrdersPage } from './Trailblazer/Orders/OrdersPage'
import { OrderPage } from './Trailblazer/Order/OrderPage'
import { PageNotFound } from './Common/PageNotFound'

interface RoutesProps {
  isLoggedIn: boolean
  darkMode?: boolean
}

export const Routes = (props: RoutesProps) => {
  const { isLoggedIn, darkMode } = props
  return (
    <Switch>
      <Route path="/" exact>
        <HomePage />
      </Route>
      <Route path="/unauthorized" exact>
        <UnauthorizedPage isLoggedIn={isLoggedIn} />
      </Route>
      <Route path="/atlas/owner/:ownerId" exact component={OwnerPage} />
      <Route path="/atlas" exact>
        <AtlasPage darkMode={darkMode} />
      </Route>
      <Route path={`${projectPlanningRootURL}`}>
        <GitHubStatsPage />
      </Route>

      {/* Protected Routes */}
      {isLoggedIn && (
        <>
          <Route path={`${ordersRootURL}`}>
            <TrailblazerHomePage isLoggedIn={isLoggedIn} />
          </Route>
          <Route path={`${analysesRootURL}`}>
            <TrailblazerHomePage isLoggedIn={isLoggedIn} />
          </Route>
          <Route path="/genotype">
            <GenotypeHomePage isLoggedIn={isLoggedIn} />
          </Route>
          <Route path="/lab-preps">
            <LabPrepsHomePage isLoggedIn={isLoggedIn} />
          </Route>
        </>
      )}
    </Switch>
  )
}

export const AnalysesRoutes = (props: RoutesProps) => {
  const { isLoggedIn } = props

  return (
    <Switch>
      {/* Protected Routes */}
      <Route
        path={`${analysesRootURL}`}
        exact
        render={() =>
          isLoggedIn === true ? (
            <Redirect to={{ pathname: `${analysesRootURL}/all-analyses` }} />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route
        path={`${analysesRootURL}/all-analyses`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/balsamic`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/mip-dna`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/mip-rna`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/fluffy`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/microsalt`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/mutant`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/demultiplex`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/rsync`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/rnafusion`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/taxprofiler`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/raw-data`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/tomte`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/raredisease`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/nallo`}
        exact
        render={() =>
          isLoggedIn === true ? <AnalysesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${analysesRootURL}/stats`}
        exact
        render={() =>
          isLoggedIn === true ? (
            <AnalysesStatsPage />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route
        path={`${analysesRootURL}/case/:familyId`}
        exact
        render={(props) =>
          isLoggedIn === true ? (
            <AnalysisPage {...props} />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route path="/*" component={PageNotFound} />
    </Switch>
  )
}
export const OrdersRoutes = (props: RoutesProps) => {
  const { isLoggedIn } = props

  return (
    <Switch>
      {/* Protected Routes */}
      <Route
        path={`${ordersRootURL}`}
        exact
        render={() =>
          isLoggedIn === true ? (
            <Redirect to={{ pathname: `${ordersRootURL}/all-orders` }} />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route
        path={`${ordersRootURL}/all-orders`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/balsamic`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/mip-dna`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/mip-rna`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/fluffy`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/microsalt`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/mutant`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/rnafusion`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/taxprofiler`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/raw-data`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/tomte`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/raredisease`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/nallo`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}`}
        exact
        render={() =>
          isLoggedIn === true ? <OrdersPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path={`${ordersRootURL}/order/:orderId`}
        exact
        render={(props) =>
          isLoggedIn === true ? (
            <OrderPage {...props.match.params} />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route path="/*" component={PageNotFound} />
    </Switch>
  )
}

export const GenotypeRoutes = (props: RoutesProps) => {
  const { isLoggedIn } = props

  return (
    <Switch>
      {/* Protected Routes */}
      <Route
        path="/genotype"
        exact
        render={() =>
          isLoggedIn === true ? (
            <Redirect to={{ pathname: `/genotype/upload` }} />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route
        path="/genotype/upload"
        exact
        render={() =>
          isLoggedIn === true ? <PlateUpload /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path="/genotype/plates"
        exact
        render={() =>
          isLoggedIn === true ? <PlateListPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path="/genotype/samples"
        exact
        render={() =>
          isLoggedIn === true ? <SamplesPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path="/genotype/plates/:id"
        exact
        render={() =>
          isLoggedIn === true ? <PlatePage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path="/genotype/match"
        exact
        render={() =>
          isLoggedIn === true ? <MatchPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path="/genotype/users"
        exact
        render={() =>
          isLoggedIn === true ? <UserListPage /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path="/genotype/users/:id"
        exact
        render={(props) =>
          isLoggedIn === true ? (
            <UserPage {...props} />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route
        path="/genotype/samples/:id"
        exact
        render={(props) =>
          isLoggedIn === true ? (
            <SamplePage {...props} />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route path="/*" component={PageNotFound} />
    </Switch>
  )
}

export const LabPrepsRoutes = (props: RoutesProps) => {
  const { isLoggedIn } = props

  return (
    <Switch>
      {/* Protected Routes */}
      <Route
        path="/lab-preps"
        exact
        render={() =>
          isLoggedIn === true ? (
            <Redirect to={{ pathname: `/lab-preps/steps` }} />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route
        path="/lab-preps/steps"
        exact
        render={() =>
          isLoggedIn === true ? <StepsTable /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route
        path="/lab-preps/received-samples"
        exact
        render={() =>
          isLoggedIn === true ? (
            <SampleTrendingPage />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route
        path="/lab-preps/turnaround-times"
        exact
        render={() =>
          isLoggedIn === true ? (
            <TurnaroundTimeTrendingPage />
          ) : (
            <Redirect to={{ pathname: `/unauthorized` }} />
          )
        }
      />
      <Route
        path="/lab-preps/preps/:id"
        exact
        render={() =>
          isLoggedIn === true ? <StepsTable /> : <Redirect to={{ pathname: `/unauthorized` }} />
        }
      />
      <Route path="/*" component={PageNotFound} />
    </Switch>
  )
}

export const GitHubRoutes = ({ issues, columnIssues, project, users, milestones, labels }) => {
  return (
    <Switch>
      <Route
        path={projectPlanningRootURL}
        exact
        render={() => <Redirect to={{ pathname: `${projectPlanningRootURL}/issues` }} />}
      />
      {/* Protected Routes */}
      <Route
        path={`${projectPlanningRootURL}/issues`}
        exact
        render={() => <Projects issues={issues} columnIssues={columnIssues} users={users} />}
      />
      <Route
        path={`${projectPlanningRootURL}/pyramid`}
        exact
        render={() => <Pyramid issues={issues} columnIssues={columnIssues} users={users} />}
      />
      <Route
        path={`${projectPlanningRootURL}/users`}
        exact
        render={() => <IssueDrivers issues={issues} columnIssues={columnIssues} users={users} />}
      />
      <Route
        path={`${projectPlanningRootURL}/users/graph`}
        exact
        render={() => <IssueDrivers issues={issues} columnIssues={columnIssues} users={users} />}
      />
      <Route
        path={`${projectPlanningRootURL}/users/table`}
        exact
        render={() => <IssueDrivers issues={issues} columnIssues={columnIssues} users={users} />}
      />
      <Route
        path={`${projectPlanningRootURL}/milestones`}
        exact
        render={() => (
          <Milestones issues={issues} columnIssues={columnIssues} milestones={milestones} />
        )}
      />
      <Route
        path={`${projectPlanningRootURL}/areas`}
        exact
        render={() => <Areas issues={issues} columnIssues={columnIssues} labels={labels} />}
      />
      <Route path="/*" component={PageNotFound} />
    </Switch>
  )
}
